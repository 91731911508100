import { axios } from '@/utils/request'

export function getArticles(parameter) {
    return axios({
        url: 'articles',
        method: 'get',
        params: parameter
    })
}

export function getArticle(articleId) {
    return axios({
        url: `articles/${articleId}`,
        method: 'get'
    })
}
