<template>
    <van-cell :title="article.title" :to="'/reader/' + article.id" class="article-item">
        <template #default>
            <my-image :src="article.thumb_vlist"></my-image>
        </template>
        <template #label>
            <van-row type="flex">
                <van-col span="24" class="meta-info">
                    <span>{{ article.category.name }}</span>
                    <span style="margin: 0px 5px"> · </span>
                    <span>{{ article.author }}</span>
                </van-col>
            </van-row>
        </template>
    </van-cell>
</template>

<script>
import MyImage from '@/components/MyImage'

export default {
    name: 'ReaderItem',
    props: {
        article: {
            required: true,
            type: Object
        }
    },
    components: { MyImage }
};
</script>

<style lang="less">
    .article-item {
        .van-cell__title {
            flex-grow: 3;
        }
        .van-cell__value {
            flex-grow: 1;
            padding-left: 5px;
        }
        .meta-info {
            display: flex;
            align-items: center;
            i {
                margin-right: 5px;
            }
            font-size: 12px;
        }
    }
</style>
