<template>
    <div>
        <van-search v-model="keyword" @search="handleSearch" @clear="handleClear" placeholder="请输入搜索关键词"/>
        <banner v-if="banners.length !== 0" :dataset="banners" />
        <van-list
            v-model="listLoading"
            :finished="listFinished"
            finished-text="没有更多了"
            @load="onLoad"
            :error.sync="error"
            error-text="加载,点击重新加载"
        >
            <reader-item v-for="article in articles.data" :key="article.id" :article="article"></reader-item>
        </van-list>
    </div>
</template>

<script>
import { getArticles } from '@/api/article'
import ReaderItem from "./ReaderItem";
import Banner from '@/components/Banner'
import { getBanners } from '@/api/banner'

export default {
    name: 'ReaderIndex',
    components: { ReaderItem, Banner },
    data () {
        return {
            keyword: null,
            listLoading: false,
            listFinished: false,
            error: false,
            banners: [],
            articles: {
                meta: {
                    current_page: 0,
                    last_page: 0,
                },
                data: []
            }
        }
    },
    methods: {
        handleSearch () {
            this.onRefresh()
        },
        handleClear () {
            this.keyword = null
            this.onRefresh()
        },
        onRefresh () {
            this.articles.data = []
            this.articles.meta.current_page = 0
            this.listFinished = false
            this.listLoading = true
            this.onLoad();
        },
        onLoad() {
            getArticles({
                page: this.articles.meta.current_page + 1,
                title: this.keyword
            }).then(res => {
                res.data.unshift(...this.articles.data)
                this.articles = res
                this.listLoading = false
                // 数据全部加载完成
                if (res.meta.current_page === res.meta.last_page) {
                    this.listFinished = true
                }
            }).catch(() => {
                this.error = true
            })
        }
    },
    created() {
        getBanners({ position: 'reader' }).then(res => {
            this.banners = res.data
        })
    }
};
</script>

<style lang="less">
    .my-swipe {
        img {
            width: 100%;
        }
    }
</style>
